import React, { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";
import DdlPriceCategory from "components/dropdowns/DdlPriceCategory";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { PRICE_DETAIL } from "utils/types/prices";

import { getArrayOfValues } from "utils/format";
import { PriceAPIv2 } from "api";

interface Props {
  data?: PRICE_DETAIL;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function OtherPricesForm(props: Props) {
  const { data, action, refetch, closeModal } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<PRICE_DETAIL>({ defaultValues: data });

  const onSubmit: SubmitHandler<PRICE_DETAIL> = useCallback(async (data) => {
    setLoading(true);
    if (data.id || action === "edit") {
      PriceAPIv2.update(data)
        .then(() => {
          setLoading(false);
        })
        .finally(() => {
          refetch();
          setLoading(false);
          closeModal();
        });
    } else {
      PriceAPIv2.add(data)
        .then(() => {
          setLoading(false);
        })
        .finally(() => {
          refetch();
          setLoading(false);
          closeModal();
        });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div id='category'>
        <p className='font-bold pb-2'>Categeory</p>
        <div
          className={`${FORM_GROUP_STYLE} ${
            isSubmitted || errors.category
              ? errors.category
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <Controller
            control={control}
            name='category'
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <DdlPriceCategory
                onChange={(e) => {
                  onChange(getArrayOfValues(e));
                }}
                value={value ? { label: value, value } : undefined}
              />
            )}
          />
        </div>
        {errors.category && (
          <span className={ERROR_STYLE}>
            {(errors as any).category.message}
          </span>
        )}
      </div>

      <div id='description'>
        <p className='font-bold pb-2'>Description</p>
        <div
          className={`${FORM_GROUP_STYLE} ${
            isSubmitted || errors.description
              ? errors.description
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <input
            disabled={isSubmitting}
            className={INPUT_STYLE}
            placeholder='Description'
            type='text'
            {...register("description", {
              required: "Description is required"
            })}
          />
          {(isSubmitted || errors.description) && (
            <div
              className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                errors.description ? "bg-rose-600" : "bg-lime-500"
              }`}
            ></div>
          )}
        </div>
        {errors.description && (
          <span className={ERROR_STYLE}>{errors.description.message}</span>
        )}
      </div>

      <div id='amount'>
        <p className='font-bold pb-2'>Amount</p>
        <div
          className={`${FORM_GROUP_STYLE} ${
            isSubmitted || errors.amount
              ? errors.amount
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <input
            disabled={isSubmitting}
            className={INPUT_STYLE}
            placeholder='Amount'
            type='text'
            {...register("amount", {
              required: "Amount is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Please provide a valid amount"
              }
            })}
          />
          {(isSubmitted || errors.amount) && (
            <div
              className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                errors.amount ? "bg-rose-600" : "bg-lime-500"
              }`}
            ></div>
          )}
        </div>
        {errors.amount && (
          <span className={ERROR_STYLE}>{(errors as any).amount.message}</span>
        )}
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create price" : "Update price"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default OtherPricesForm;
