import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Modal from "components/common/Modal";
import Loader from "components/common/Loader";

import { useUser } from "context/user.context";
import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";

import { UsersAPI } from "api";

type Inputs = {
  id: string;
  username: string;
  password?: string;
  confirmPassword?: string;
};

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const Profile = ({ open, setOpen }: Props) => {
  const { ctxUser, changeToken, changeUser, changeAuthStatus } = useUser();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<Inputs>();

  const password = useRef({});
  password.current = watch("password", "");
  const confirmPassword = useRef({});
  confirmPassword.current = watch("confirmPassword", "");

  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [showCPwd, setShowCPwd] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const resProfile = await UsersAPI.login(data);

    if (resProfile.error) {
      toast.error(resProfile.message, { position: "bottom-right" });
    } else {
      // log out to allow user connect with new password
      toast.success("Credentials updated successfully", {
        position: "bottom-right"
      });
      changeToken("");
      changeUser({});
      changeAuthStatus(false);
      localStorage.removeItem("token");
      setOpen(false);
      navigate("/");
    }
  };

  useEffect(() => {
    if (ctxUser && open) {
      ["id", "username"].forEach((key: string) => {
        setValue(key as any, ctxUser[key]);
      });
    }
  }, [open, ctxUser]);

  return (
    <div>
      <Modal
        isOpen={open}
        onClose={() => {
          reset();
          setOpen(false);
        }}
        title='Profile'
        className='md:max-w-[700px] lg:max-w-[700px]'
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col'
          autoComplete='off'
        >
          <p className='font-semibold'>username</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted
                ? errors.username
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting}
              className={INPUT_STYLE}
              placeholder='username'
              type='text'
              {...register("username", { required: true })}
            />
            {isSubmitted && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.username ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.username && (
            <span className={ERROR_STYLE}>Username is required</span>
          )}

          <p className='font-semibold'>password</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted
                ? errors.password
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='password'
              rules={{
                required: "Password is required"
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  {showPwd ? (
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} !pr-20`}
                      placeholder='password'
                      type='text'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                    />
                  ) : (
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} !pr-20`}
                      placeholder='password'
                      type='password'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                    />
                  )}
                </>
              )}
            />

            {password.current && (
              <button
                className='block absolute top-2 right-10'
                onClick={() => setShowPwd(!showPwd)}
              >
                <FontAwesomeIcon icon={showPwd ? faEyeSlash : faEye} />
              </button>
            )}
            {isSubmitted && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.password ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.password && (
            <span className={ERROR_STYLE}>{errors.password.message}</span>
          )}

          <p className='font-semibold'>confirm password</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted
                ? errors.confirmPassword
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='confirmPassword'
              rules={{
                required: "Confirm Password is required",
                validate: (value) => {
                  if (!value) {
                    return "Confirm Password is required";
                  }
                  if (value === password.current) return true;
                  return "The passwords do not match";
                }
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  {showCPwd ? (
                    <input
                      disabled={isSubmitting}
                      className={INPUT_STYLE}
                      placeholder='confirmPassword'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                      type='text'
                    />
                  ) : (
                    <input
                      disabled={isSubmitting}
                      className={INPUT_STYLE}
                      placeholder='confirmPassword'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                      type='password'
                    />
                  )}
                </>
              )}
            />

            {confirmPassword.current && (
              <button
                className='block absolute top-2 right-10'
                onClick={() => setShowCPwd(!showCPwd)}
              >
                <FontAwesomeIcon icon={showCPwd ? faEyeSlash : faEye} />
              </button>
            )}
            {isSubmitted && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.confirmPassword ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.confirmPassword && (
            <span className={ERROR_STYLE}>
              {errors.confirmPassword.message}
            </span>
          )}

          {isSubmitting ? (
            <div className={`${SUBMIT_STYLE} bg-gray-100`}>
              <Loader width='w-6' height='h-6' text='text-gray-300' />
            </div>
          ) : (
            <input
              type='submit'
              disabled={isSubmitting}
              value='Update Profile'
              className={`${SUBMIT_STYLE} bg-blue-200`}
            />
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Profile;
