import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";

import moment from "moment";
import {
  pdfStyles,
  tableStyles
} from "components/templates/InvoiceHTMLTemplate.style";
import {
  amountLine,
  getAmountStr,
  sortInvoicePaymentByDate
} from "utils/format/invoice";
import { INVOICE } from "utils/types/invoice";
import { USER } from "utils/types/user";

export interface Props {
  data: INVOICE;
  student: Partial<USER>;
  paymentLabel: string;
  dateDue: string;
  total: number;
  totalWithPenalties: number;
  amountDue: number;
  amountPaid: number;
  isReceipt: boolean;
}

const InvoiceHTMLTemplate = ({
  data,
  student,
  paymentLabel,
  dateDue,
  total,
  totalWithPenalties,
  amountDue,
  amountPaid,
  isReceipt
}: Props) => {
  Font.register({
    family: "Montserrat",
    fonts: [
      { src: "/fonts/Montserrat-Light.ttf" },
      { src: "/fonts/Montserrat-SemiBold.ttf", fontWeight: 700 },
      { src: "/fonts/Montserrat-Italic.ttf", fontStyle: "italic" }
    ]
  });

  return (
    <Page size='A4' style={{ padding: "40px" }}>
      <View style={pdfStyles.headerWrapper}>
        <View style={pdfStyles.imgWrapper}>
          <Image style={pdfStyles.imgStyles} src='/images/logo-big.png' cache />
        </View>

        <View style={pdfStyles.esaInfo}>
          <Text style={pdfStyles.textBold}>
            ELEMENTARY SCHOOL OF ANTANANARIVO
          </Text>
          <Text style={pdfStyles.textStyle}>Amboara Ambohitrarahaba</Text>
          <Text style={pdfStyles.textStyle}>contact@esa-mada.com</Text>
          <Text style={pdfStyles.textStyle}>+261 34 40 216 81</Text>
          <Text style={pdfStyles.textStyle}>
            NIF: 4003517556 - STAT: 11 2019 0 05 848
          </Text>
        </View>
      </View>

      <View style={pdfStyles.headerWrapper}>
        <View style={pdfStyles.infoPaid}>
          <Text
            style={
              data.type === "invoice"
                ? pdfStyles.badgeDanger
                : pdfStyles.badgeSuccess
            }
          >
            {data.type.toUpperCase()}
          </Text>

          <Text style={pdfStyles.textStyle}>{paymentLabel}</Text>
        </View>

        <View style={pdfStyles.invoiceInfoWrapper}>
          <View style={pdfStyles.row}>
            <Text style={pdfStyles.textUnderlined}>INVOICE NUMBER:</Text>
            <Text style={pdfStyles.textStyle}>
              {data.reference && data.reference}
            </Text>
          </View>

          <View style={pdfStyles.row}>
            <Text style={pdfStyles.textUnderlined}>DATE:</Text>
            {data.date_issue && (
              <Text style={pdfStyles.textStyle}>
                {moment(data.date_issue).format("DD/MM/YYYY")}
              </Text>
            )}
          </View>

          <View style={[pdfStyles.row, { marginBottom: "15px" }]}>
            <Text style={pdfStyles.textUnderlined}>STUDENT CODE:</Text>
            <Text style={pdfStyles.textStyle}>{data.codeEleve}</Text>
          </View>

          {student.dad && (
            <Text style={pdfStyles.textStyle}>{student.dad.name}</Text>
          )}
          {student.mom && (
            <Text style={pdfStyles.textStyle}>{student.mom.name}</Text>
          )}
          {student.address && (
            <Text style={pdfStyles.textStyle}>{student.address}</Text>
          )}
        </View>
      </View>

      <View style={[pdfStyles.row, { marginBottom: "15px" }]}>
        <Text style={pdfStyles.textUnderlined}>Concerns:</Text>
        <Text style={pdfStyles.textStyle}>{student.name && student.name}</Text>
      </View>

      <View style={tableStyles.row}>
        <Text style={[tableStyles.thead, tableStyles.colDescription]}>
          Description
        </Text>
        <Text style={[tableStyles.thead, tableStyles.colQuantity]}>
          Qty
        </Text>
        <Text style={[tableStyles.thead, tableStyles.colUnitPrice]}>
          Unit Price (Ar)
        </Text>
        <Text style={[tableStyles.thead, tableStyles.colAmount]}>
          Amount (Ar)
        </Text>
      </View>

      {data.details &&
        data.details.length > 0 &&
        data.details.map((detail: any, iDet: number) => (
          <View key={iDet} style={tableStyles.row}>
            <View style={tableStyles.colDescription}>
              <Text style={pdfStyles.textStyleSmall}>{detail.description.replace(/\u202F/g, ' ')}</Text>
              {detail.discount && (
                <Text
                  style={tableStyles.discountStyle}
                >{`${getAmountStr(detail.discount)} Ar`}</Text>
              )}
            </View>
            <Text style={[pdfStyles.textStyleSmall, tableStyles.colQuantity]}>
              {detail.quantity ? detail.quantity : 1}
            </Text>
            <Text
              style={[pdfStyles.textStyleSmall, tableStyles.colUnitPrice]}
            >{`${getAmountStr(detail.amount)} Ar`}</Text>
            <Text
              style={[pdfStyles.textStyleSmall, tableStyles.colAmount]}
            >{`${amountLine(detail)} Ar`}</Text>
          </View>
        ))}

      <View style={tableStyles.row}>
        <Text style={[tableStyles.tfoot, tableStyles.colTotal]}>TOTAL</Text>
        <Text
          style={[tableStyles.tfoot, tableStyles.colAmount]}
        >{`${getAmountStr(total)} Ar`}</Text>
      </View>

      <View style={[pdfStyles.row, { marginTop: 15 }]}>
        <Text style={pdfStyles.textUnderlined}>Payment Deadline:</Text>
        <Text style={pdfStyles.textStyle}>{dateDue}</Text>
      </View>

      <Text
        style={[pdfStyles.textStyle, { fontStyle: "italic", marginBottom: 15 }]}
      >
        Beyond this date, a penalty of 10% (of the total payment) every
        fortnight will be applied.
      </Text>

      {data.penalties && data.penalties.length > 0 && (
        <View>
          <Text style={[pdfStyles.textUnderlined, { margin: 0 }]}>
            Penalties:
          </Text>
          {sortInvoicePaymentByDate(data.penalties).map(
            (penalty: any, iPenalty: number) => (
              <View key={`penalty${iPenalty}`} style={tableStyles.row}>
                <Text style={[pdfStyles.textStyle, tableStyles.colPenalty]}>
                  {`Penalty n°${iPenalty + 1} on ${penalty.date ? `on ${moment(penalty.date).format("DD/MM/YYYY")}` : ""}`}
                </Text>
                <Text
                  style={[pdfStyles.textStyle, tableStyles.colPenaltyPrice]}
                >
                  {`+${getAmountStr(penalty.amount)} Ar`}
                </Text>
              </View>
            )
          )}
          <View style={[tableStyles.row, tableStyles.totalWithPenalties]}>
            <Text style={pdfStyles.textBold}>Total with penalties</Text>
            <Text
              style={pdfStyles.textBold}
            >{`${getAmountStr(totalWithPenalties)} Ar`}</Text>
          </View>
        </View>
      )}

      {(data.amount_paid !== 0 ||
        (data.payments && data.payments.length > 0)) && (
        <View style={{ marginTop: 20 }}>
          <Text style={[pdfStyles.textUnderlined, { margin: 0 }]}>
            Payments:
          </Text>
          {data.amount_paid !== 0 && (
            <View style={tableStyles.row}>
              <Text style={[pdfStyles.textStyle, tableStyles.colPenalty]}>
                Previous Payment
              </Text>
              <Text style={[pdfStyles.textStyle, tableStyles.colPenaltyPrice]}>
                {`- ${getAmountStr(data.amount_paid)} Ar`}
              </Text>
            </View>
          )}
          {data.payments &&
            data.payments.length > 0 &&
            sortInvoicePaymentByDate(data.payments).map((payment, iPayment) => (
              <View key={`payment${iPayment}`} style={tableStyles.row}>
                <Text style={[pdfStyles.textStyle, tableStyles.colPenalty]}>
                  {`Payment n°${iPayment + 1} ${payment.date ? `on ${moment(payment.date).format("DD/MM/YYYY")}` : ""}`}
                </Text>
                <Text
                  style={[pdfStyles.textStyle, tableStyles.colPenaltyPrice]}
                >
                  {`-${getAmountStr(payment.amount)} Ar`}
                </Text>
              </View>
            ))}
          <View style={[tableStyles.row, tableStyles.totalDue]}>
            <Text style={pdfStyles.textBold}>Total paid</Text>
            <Text
              style={pdfStyles.textBold}
            >{`${getAmountStr(amountPaid)} Ar`}</Text>
          </View>
        </View>
      )}

      {!isReceipt &&
        ((data.penalties && data.penalties.length > 0) ||
          (data.payments && data.payments.length > 0)) && (
          <View style={[tableStyles.row, tableStyles.remaining]}>
            <Text style={pdfStyles.textStyle}>Remaining</Text>
            <Text
              style={pdfStyles.textStyle}
            >{`${getAmountStr(amountDue)} Ar`}</Text>
          </View>
        )}
      <View
        style={{
          width: "100%",
          borderTop: "1px solid #dadada",
          marginVertical: "15px"
        }}
      />

      <Text style={[pdfStyles.textBold, { marginBottom: "10px" }]}>
        {data.description && data.description !== "" ? "Description" : ""}
      </Text>
      <Text style={pdfStyles.textStyle}>
        {data.description && data.description !== "" ? data.description : ""}
      </Text>
    </Page>
  );
};

export default InvoiceHTMLTemplate;
