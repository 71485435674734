import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import Calendar from "components/agenda/Calendar";
import DdlLevel from "components/dropdowns/DdlLevel";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import DdlTerm from "components/dropdowns/DdlTerm";
import DdlWeek from "components/dropdowns/DdlWeek";
import Loader from "components/common/Loader";

import { LevelsAPI } from "api";
import { useUser } from "context/user.context";

import { getDefaultAgendaHeaders } from "utils/format/term";
import { getTerms } from "utils/api_calls/term";
import { getCurrentSchoolYear } from "utils/dates";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import { LEVEL } from "utils/types/level";
import { TERM, WEEK } from "utils/types/term";

function Page() {
  const { ctxUser } = useUser();
  const navigate = useNavigate();
  const [allLevels, setAllLevels] = useState<LEVEL[]>([]);
  const [terms, setTerms] = useState<TERM[]>([]);
  const [levels, setLevels] = useState<LEVEL[]>([]);

  const [schoolYear, setSchoolYear] = useState<string>(getCurrentSchoolYear());
  const [level, setLevel] = useState<LEVEL>();
  const [term, setTerm] = useState<TERM>();
  const [week, setWeek] = useState<WEEK>();

  const [goTo, setGoto] = useState("");
  const [loading, setLoading] = useState<string>("");

  const initializeValues = useCallback(
    async (schoolYearParam?: string) => {
      if (ctxUser.id === "") {
        navigate("/");
        return;
      }

      setLoading("init");
      const resTermsPromise = await getTerms(schoolYearParam).then(
        (r: TERM[]) => r
      );

      let resLevelPromise: Promise<{ data: any[] }> = Promise.resolve({
        data: []
      });

      if (["ADMIN", "TUTOR"].includes(ctxUser.role)) {
        resLevelPromise = await LevelsAPI.search(null, null, true).then(
          (r: any) => r
        );
      }

      const results = await Promise.all([resTermsPromise, resLevelPromise]);
      const resTerms = results[0];
      const resLevels = results[1];

      setTerms(resTerms);
      setAllLevels(resLevels.data);

      const defaultValues = getDefaultAgendaHeaders(resTerms);
      setTerm(defaultValues.term);
      setWeek(defaultValues.week);
      setLoading("");
    },
    [ctxUser]
  );

  useEffect(() => {
    initializeValues();
  }, []);

  useEffect(() => {
    if (ctxUser && ctxUser.id) {
      if (
        ctxUser.role === "TUTOR" &&
        ctxUser.level &&
        typeof ctxUser.level === "object" &&
        ctxUser.level.length > 0
      ) {
        // as tutors level are Array of string, remove levels that they are not assigned to
        setLevels(
          allLevels.filter((item: LEVEL) => ctxUser.level.includes(item.value))
        );
      }
      const userLevel =
        typeof ctxUser.level === "string"
          ? ctxUser.level
          : ctxUser.level.length > 0
            ? ctxUser.level[0]
            : "";

      if (userLevel === "") {
        setLevel(allLevels.find((level: LEVEL) => level.value === "Nursery"));
      } else {
        setLevel(allLevels.find((level: LEVEL) => level.value === userLevel));
      }
    }
  }, [ctxUser, allLevels]);

  return (
    <div id='agenda-page' className={`${BG_COLORED} py-8`}>
      <section
        id='agenda-header'
        className={`${CONTAINER} ${CONTAINER_PADDING} pb-0`}
      >
        {term && week ? (
          <h1 className='text-center pb-8 font-bold'>
            {`${level ? level.label : ctxUser.level} Schedule ${term && term ? "for term" : ""} 
              ${term && term.termNb ? `${term.termNb}` : ""} - 
              ${`Week ${week.weekNb}`}
            `}
            <br />
            {`
              from ${moment(week.start).format("dddd DD MMMM YYYY")} to 
              ${moment(week.end).format("dddd DD MMMM YYYY")}
            `}
          </h1>
        ) : (
          <div className='flex items-center justify-center pb-8'>
            <Loader />
          </div>
        )}

        {/* HEADER */}
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
          {["ADMIN", "TUTOR"].includes(ctxUser.role) ? (
            <DdlSchoolYear
              disabled={loading !== ""}
              className='rounded-3xl shadow-md'
              onChange={(e: any) => {
                setSchoolYear(e.value);
                initializeValues(e.value);
              }}
              value={{ label: schoolYear, value: schoolYear }}
            />
          ) : (
            <div>
              <p className='font-bold'>School year</p>
              <p>{schoolYear}</p>
            </div>
          )}

          {["ADMIN", "TUTOR", "SECRETARIAT"].includes(ctxUser.role) ? (
            <DdlLevel
              disabled={loading !== ""}
              className='rounded-3xl shadow-md'
              onChange={(l: LEVEL) => setLevel(l)}
              data={levels.length > 0 ? levels : allLevels}
              value={
                level ? { label: level.label, value: level.value } : undefined
              }
            />
          ) : (
            <div>
              <p className='font-bold'>Level</p>
              <p>{ctxUser.level}</p>
            </div>
          )}
          <DdlTerm
            disabled={loading !== ""}
            className='rounded-3xl shadow-md'
            onChange={(e: TERM) => {
              if (e.start !== "" && e.end !== "") {
                setTerm(e);
                if (week && week.weekNb === "") {
                  setWeek({ ...week, start: e.start, end: e.end });
                } else {
                  const temp = e.weeks.find(
                    (item: WEEK) => item.weekNb === week.weekNb
                  );
                  setWeek({
                    ...week,
                    start: temp ? temp.start : e.start,
                    end: temp ? temp.end : e.end
                  });
                }
              } else {
                toast.error(
                  "Dates for the selected term are not available yet",
                  {
                    position: "bottom-right"
                  }
                );
              }
            }}
            data={terms || []}
            value={
              term
                ? { label: `Term ${term.termNb}`, value: term.termNb }
                : undefined
            }
          />
          <DdlWeek
            disabled={loading !== ""}
            className='rounded-3xl shadow-md'
            data={term ? term.weeks : []}
            onChange={(w: WEEK) => {
              if (w.weekNb === "") {
                setWeek({ ...week, start: term.start, end: term.end });
                setGoto(term.start);
              } else {
                setWeek(w);
                setGoto(w.start);
              }
            }}
            value={
              term && week
                ? { label: `Week ${week.weekNb}`, value: week.weekNb }
                : undefined
            }
          />
        </div>
        {/* END HEADER */}
      </section>

      <section
        id='agenda-calendar'
        className={`${CONTAINER} ${CONTAINER_PADDING} py-4`}
      >
        {/* CALENDAR */}
        {term && (
          <Calendar
            loading={loading}
            setLoading={setLoading}
            term={term}
            level={
              level || {
                id: "",
                label: ctxUser.level as any,
                quizz: "",
                value: ctxUser.level as any,
                subjects: []
              }
            }
            week={week}
            setWeek={setWeek}
            goTo={goTo}
            terms={terms}
            levels={levels.length > 0 ? levels : allLevels}
            schoolYear={schoolYear}
            canEdit={["ADMIN", "TUTOR", "SECRETARIAT"].includes(ctxUser.role)}
          />
        )}
        {/* END CALENDAR */}
      </section>
    </div>
  );
}

export default Page;
