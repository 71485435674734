import React, { useCallback, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";

import { InputSwitch } from "primereact/inputswitch";
import DdlLevel from "components/dropdowns/DdlLevel";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import FileUpload from "components/common/FileUpload";
import Loader from "components/common/Loader";
import RichText from "components/common/RichText";

import { TermAPI, TimetableAPI, UploadAPI } from "api";
import { useUser } from "context/user.context";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { LEVEL } from "utils/types/level";
import { TERM } from "utils/types/term";

interface Props {
  data?: any;
  action: ACTIONS;
  closeModal: any;
  terms: TERM[];
  levels: LEVEL[];
  refetch: any;
}

function AgendaForm(props: Props) {
  const { data, action, closeModal, terms, levels, refetch } = props;
  const { ctxUser } = useUser();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<any>({
    defaultValues: {
      ...data,
      level: { label: data.level, value: data.level },
      school_year: { label: data.school_year, value: data.school_year },
      recurrent: data.reccurent === 1
    }
  });

  const watchDate = watch("date");
  const watchRecurrent = watch("recurrent");
  const watchTermNumber = watch("termNumber");

  const addUpdate = useCallback(
    async (data: any) => {
      const { level, school_year, id, recurrent, ...rest } = data;

      const dataToSend = {
        ...rest,
        level: level.value,
        school_year: school_year.value,
        recurrent: recurrent ? 1 : 0
      };

      if (data.id || action === "edit") {
        await TimetableAPI.update({
          ...dataToSend,
          id: id
        });
      } else {
        await TimetableAPI.add(dataToSend);
      }
      setLoading(false);
      setTimeout(() => {
        refetch();
        closeModal();
      }, 1000);
    },
    [action]
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      setLoading(true);
      if (data.files) {
        UploadAPI.upload("timetable", data.files).then((r) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { files, ...rest } = data;
          addUpdate({ ...rest, files: r });
        });
      } else {
        addUpdate({ ...data, files: [] });
      }
    },
    [action]
  );

  const canEdit = useMemo(() => {
    if (ctxUser && ["ADMIN", "TUTOR"].includes(ctxUser.role)) {
      return true;
    }
    return false;
  }, [ctxUser]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div id='school_year'>
          <p className='font-bold pb-2'>School Year</p>
          {canEdit ? (
            <>
              <div
                className={`${FORM_GROUP_STYLE} ${
                  isSubmitted || errors.school_year
                    ? errors.school_year
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <Controller
                  control={control}
                  name='school_year'
                  rules={{ required: "Please provide a school year" }}
                  render={({ field: { onChange, value } }) => (
                    <DdlSchoolYear onChange={onChange} value={value} />
                  )}
                />
              </div>
              {errors.school_year && (
                <span className={ERROR_STYLE}>
                  {(errors as any).school_year.message}
                </span>
              )}
            </>
          ) : (
            <p>{data.school_year}</p>
          )}
        </div>
        <div id='Level'>
          <p className='font-bold pb-2'>Level</p>
          {canEdit ? (
            <>
              <div
                className={`${FORM_GROUP_STYLE} ${
                  isSubmitted || errors.level
                    ? errors.level
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <Controller
                  control={control}
                  name='level'
                  rules={{ required: "Please provide a level" }}
                  render={({ field: { onChange, value } }) => (
                    <DdlLevel
                      data={levels}
                      onChange={onChange}
                      value={
                        value
                          ? { label: value.label, value: value.value }
                          : undefined
                      }
                    />
                  )}
                />
              </div>
              {errors.level && (
                <span className={ERROR_STYLE}>
                  {(errors as any).level.message}
                </span>
              )}
            </>
          ) : (
            <p>{data.level}</p>
          )}
        </div>
      </div>

      <div id='date_time' className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div id='date'>
          <p className={`font-bold pb-2 ${canEdit ? "" : "pt-8"}`}>
            Date(MM/DD/YYYY)
          </p>
          {canEdit ? (
            <>
              <div
                className={`${FORM_GROUP_STYLE} ${
                  isSubmitted || errors.date
                    ? errors.date
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <Controller
                  control={control}
                  name='date'
                  rules={{ required: "Please choose a date" }}
                  render={({ field: { onChange, value } }) => (
                    <input
                      disabled={isSubmitting}
                      className={INPUT_STYLE}
                      placeholder='MM/DD/YYYY'
                      type='date'
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setValue(
                          "termNumber",
                          Number(
                            TermAPI.getCurrentTerm(terms, e.target.value).termNb
                          )
                        );
                      }}
                    />
                  )}
                />
                {(isSubmitted || errors.date) && (
                  <div
                    className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                      errors.date ? "bg-rose-600" : "bg-lime-500"
                    }`}
                  ></div>
                )}
              </div>
              {errors.date && (
                <span className={ERROR_STYLE}>
                  {(errors as any).date.message}
                </span>
              )}
            </>
          ) : (
            <p>{data.date}</p>
          )}
        </div>
        <div id='times' className='grid grid-cols-2 gap-4'>
          {canEdit ? (
            <>
              {["timeStart", "timeEnd"].map((timeKey: string) => {
                const label = timeKey === "timeStart" ? "start" : "end";
                return (
                  <div id={timeKey} key={timeKey}>
                    <p className='font-bold pb-2 capitalize'>{label}</p>
                    <div
                      className={`${FORM_GROUP_STYLE} ${
                        isSubmitted || errors[timeKey]
                          ? errors[timeKey]
                            ? "border-rose-600"
                            : "border-lime-500"
                          : ""
                      }`}
                    >
                      <input
                        disabled={isSubmitting}
                        className={INPUT_STYLE}
                        placeholder='hh:mm'
                        type='text'
                        {...register(timeKey, {
                          required: `Please provide a ${label} time`,
                          pattern: {
                            value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                            message: "Invalid time format. Use HH:mm"
                          }
                        })}
                      />
                      {(isSubmitted || errors[timeKey]) && (
                        <div
                          className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                            errors[timeKey] ? "bg-rose-600" : "bg-lime-500"
                          }`}
                        ></div>
                      )}
                    </div>
                    {errors[timeKey] && (
                      <span className={ERROR_STYLE}>
                        {(errors as any)[timeKey].message}
                      </span>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className='pt-12'>
              <p>From: {data.timeStart}</p>
              <p>To: {data.timeEnd}</p>
            </div>
          )}
        </div>
      </div>

      <div id='recurrent' className='flex flex-row flex-wrap'>
        {canEdit && (
          <Controller
            control={control}
            name='recurrent'
            render={({ field: { onChange, value } }) => (
              <div className='flex flex-row items-center'>
                <InputSwitch
                  checked={value}
                  onChange={(e) => onChange(e.value)}
                />
              </div>
            )}
          />
        )}
        <div className={`flex flex-col ml-4 ${canEdit ? "min-h-12" : ""}`}>
          {canEdit && <p>Recurrent event</p>}
          {watchRecurrent && watchDate && (
            <p className='font-bold'>{`Every ${moment(watchDate).format(
              "dddd"
            )} for Term  ${watchTermNumber}`}</p>
          )}
        </div>
      </div>

      <div id='subjectName' className='pt-8'>
        <p className='font-bold pb-2'>Subject name</p>
        {canEdit ? (
          <>
            <div
              className={`${FORM_GROUP_STYLE} mb-2 ${
                isSubmitted || errors.subjectName
                  ? errors.subjectName
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Subject name'
                type='text'
                {...register("subjectName", { required: true })}
              />
              {(isSubmitted || errors.subjectName) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.subjectName ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.subjectName && (
              <span className={ERROR_STYLE}>Subject name is required</span>
            )}
          </>
        ) : (
          <p>{data.subjectName}</p>
        )}
      </div>

      <div id='cahierDeTexte.description'>
        <p className='font-bold pb-2 pt-4'>Description</p>
        {canEdit ? (
          <>
            <div
              className={`${FORM_GROUP_STYLE} mb-2 rounded-none ${
                isSubmitted || (errors as any).cahierDeTexte?.description
                  ? (errors as any).cahierDeTexte?.description
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='cahierDeTexte.description'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <RichText onChange={onChange} currentHtmlValue={value} />
                )}
              />
            </div>
            {(errors as any).cahierDeTexte?.description && (
              <span className={ERROR_STYLE}>
                {(errors as any).cahierDeTexte?.description?.message}
              </span>
            )}
          </>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: data.description || "no description"
            }}
          />
        )}
      </div>

      <div id='files'>
        <p className={`font-bold pb-2 ${canEdit ? "" : "pt-6"}`}>Files</p>
        {canEdit ? (
          <>
            <div
              className={`${FORM_GROUP_STYLE} rounded-none ${
                isSubmitted || errors.files
                  ? errors.files
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='files'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FileUpload
                      data={value}
                      onSubmit={onChange}
                      autoSubmit={true}
                    />
                  );
                }}
              />
            </div>
            {errors.files && (
              <span className={ERROR_STYLE}>
                {(errors as any).files.message}
              </span>
            )}
          </>
        ) : (
          <>
            {([null, undefined].includes(data.files) ||
              data.files.length === 0) && <p>no files</p>}
            {(data.files || []).map((item: any) => (
              <a
                href={item.url}
                key={item.url}
                target='_blank'
                rel='noreferrer'
              >
                {item.name}
              </a>
            ))}
          </>
        )}
      </div>

      <div id='cahierDeTexte.broadcastLink'>
        <p className={`font-bold pb-2 ${canEdit ? "" : "pt-6"}`}>
          Broadcast link
        </p>
        {canEdit ? (
          <>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || (errors as any).cahierDeTexte?.broadcastLink
                  ? (errors as any).cahierDeTexte?.broadcastLink
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Broadcast link'
                type='text'
                {...register("cahierDeTexte.broadcastLink", {
                  required: false
                })}
              />
              {(isSubmitted ||
                (errors as any).cahierDeTexte?.broadcastLink) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    (errors as any).cahierDeTexte?.broadcastLink
                      ? "bg-rose-600"
                      : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {(errors as any).cahierDeTexte?.broadcastLink && (
              <span className={ERROR_STYLE}>Broadcast link is required</span>
            )}
          </>
        ) : (
          <p>{data.cahierDeTexte?.broadcastLink || "no broadcast link"}</p>
        )}
      </div>

      {canEdit && (
        <div id='btnSubmit' className='w-full flex items-center justify-center'>
          {isSubmitting || loading ? (
            <div className={`${SUBMIT_STYLE} bg-gray-100`}>
              <Loader width='w-6' height='h-6' text='text-gray-300' />
            </div>
          ) : (
            <input
              type='submit'
              disabled={isSubmitting}
              value={action === "add" ? "Create event" : "Update event"}
              className={`${SUBMIT_STYLE} bg-blue-300`}
            />
          )}
        </div>
      )}
    </form>
  );
}

export default AgendaForm;
