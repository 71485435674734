import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Loader from "components/common/Loader";
import AgendaModal from "components/agenda/AgendaModal";

import { TimetableAPI } from "api";
import { useUser } from "context/user.context";

import { LEVEL } from "utils/types/level";
import { TERM, WEEK } from "utils/types/term";
import { DEFAULT_EVENT } from "utils/constants/agenda";
import { ACTIONS } from "utils/types/general";
import { useWindowSize } from "usehooks-ts";

interface Props {
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
  term: TERM;
  level: LEVEL;
  terms: TERM[];
  levels: LEVEL[];
  week: WEEK;
  setWeek: Dispatch<SetStateAction<WEEK>>;
  goTo: string;
  schoolYear: string;
  canEdit: boolean;
}

function Calendar(props: Props) {
  const {
    loading,
    setLoading,
    term,
    level,
    terms,
    levels,
    week,
    setWeek,
    goTo,
    schoolYear,
    canEdit
  } = props;
  const { ctxUser } = useUser();
  const { width } = useWindowSize();
  const calendarRef = useRef<FullCalendar>(null);
  const [events, setEvents] = useState<any[]>([]);
  // const [gridView, setGridView] = useState("timeGridWeek");
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [action, setAction] = useState<ACTIONS | any>();

  const [fullCalendarHeader, setFullCalendarHeader] = useState<
    Record<string, string>
  >({
    left: "prev, next",
    center: "",
    right: "timeGridWeek, listTheWeek, listAll"
  });

  const getEvents = useCallback(async () => {
    if (term && level) {
      setLoading("fetch");
      TimetableAPI.get(level.value, term.start, term.end)
        .then((r: any) => {
          setEvents(r);
        })
        .finally(() => setLoading(""));

      if (goTo !== "" && calendarRef.current !== null) {
        calendarRef.current.getApi().gotoDate(goTo);
      }
    }
  }, [term, level]);

  useEffect(() => {
    if (goTo !== "" && calendarRef.current !== null) {
      calendarRef.current.getApi().gotoDate(goTo);
    }
  }, [goTo]);

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (width < 768) {
      setFullCalendarHeader({ ...fullCalendarHeader, left: "", right: "" });
    } else {
      setFullCalendarHeader({
        ...fullCalendarHeader,
        right: "timeGridWeek, listTheWeek, listAll"
      });
    }
  }, [width]);
  const addEvent = useCallback(
    (cellData?: any) => {
      if (ctxUser.role !== "PARENT") {
        const defaultDate = cellData
          ? cellData.startStr.substr(0, 10)
          : moment(new Date()).format("YYYY-MM-DD");

        const startTime = cellData
          ? cellData.startStr.substr(11, 16).substr(0, 5)
          : moment(new Date()).format("HH:mm");

        const endTime = cellData
          ? cellData.endStr.substr(11, 16).substr(0, 5)
          : moment(new Date()).format("HH:mm");

        if (term) {
          setSelectedEvent({
            ...DEFAULT_EVENT,
            termNumber: term.termNb,
            weekNumber: week.weekNb,
            date: defaultDate,
            timeStart: startTime,
            timeEnd: endTime,
            level: level.value,
            school_year: schoolYear
          });
        } else {
          setSelectedEvent({
            ...DEFAULT_EVENT,
            weekNumber: week.weekNb,
            date: defaultDate,
            timeStart: startTime,
            timeEnd: endTime,
            level: level.value,
            school_year: schoolYear
          });
        }

        setAction("add");
      }
    },
    [ctxUser, week, level, schoolYear]
  );

  const handleEventClick = useCallback((cellData: any) => {
    if (cellData.event === undefined) {
      addEvent(cellData);
    } else {
      const d = cellData.event._def.extendedProps.data;
      setSelectedEvent({
        id: d.id,
        level: d.level,
        date: d.date,
        timeStart: d.timeStart,
        timeEnd: d.timeEnd,
        weekNumber: d.weekNumber,
        termNumber: d.termNumber,
        subjectName: d.subjectName,
        recurrent: d.recurrent,
        files: d.files,
        cahierDeTexte: d.cahierDeTexte,
        school_year: d.school_year
      });
      setAction("edit");
    }
  }, []);

  const renderEventContent = useCallback((eventInfo: any) => {
    let myEvent = eventInfo.event.extendedProps.data;
    myEvent = myEvent === undefined ? DEFAULT_EVENT : myEvent;
    return (
      <div className='text-slate-600'>
        <div className='flex flex-row'>
          {ctxUser.role === "PARENT" ? null : (
            <button
              className={`text-rose-500 hover:text-white mr-3`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEvent(myEvent);
                setAction("delete");
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
          <p className='font-bold'>{eventInfo.timeText}</p>
        </div>

        <p className='event-title'>
          {myEvent.subjectName}{" "}
          {myEvent.files !== null && myEvent.files.length > 0 && (
            <FontAwesomeIcon icon={faFile} className='text-esaMintyDark ml-2' />
          )}
        </p>

        {myEvent.cahierDeTexte.description !== "" && (
          <div
            className='description'
            dangerouslySetInnerHTML={{
              __html: myEvent.cahierDeTexte.description
            }}
          />
        )}

        {myEvent.cahierDeTexte.broadcastLink !== "" && (
          <p className='broadcast'>
            <b>Broadcast</b>:
            {/* <a href={myEvent.cahierDeTexte.broadcastLink} target="_blank" rel="noreferrer"> */}
            <span>{myEvent.cahierDeTexte.broadcastLink}</span>
            {/* </a> */}
          </p>
        )}
      </div>
    );
  }, []);

  return (
    <>
      {loading !== "" ? (
        <Loader />
      ) : (
        <>
          <div className='w-full flex justify-end pb-4'>
            {canEdit && (
              <button
                className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-3xl px-4 py-2 shadow-md min-h-12'
                onClick={() => {
                  setSelectedEvent(DEFAULT_EVENT);
                  setAction("add");
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className='ml-2 font-semibold'>Add event</span>
              </button>
            )}
          </div>
          <FullCalendar
            ref={calendarRef}
            events={events}
            stickyHeaderDates
            initialDate={week.start}
            initialView={width < 768 ? "listAll" : "timeGridWeek"}
            plugins={[timeGridPlugin, interactionPlugin, listPlugin]}
            windowResize={(arg) => {
              const isSmallScreen = window.innerWidth < 768;
              arg.view.calendar.changeView(
                isSmallScreen ? "listAll" : "timeGridWeek"
              );
            }}
            views={{
              listTheWeek: {
                type: "list",
                duration: {
                  days: moment
                    .utc(week.end, "YYYY-MM-DD")
                    .diff(moment.utc(week.start, "YYYY-MM-DD"), "days")
                },
                buttonText: `Week ${week.weekNb} List`
              },
              listAll: {
                type: "list",
                duration: {
                  days: term
                    ? moment(term.end, "YYYY-MM-DD").diff(
                        moment(term.start, "YYYY-MM-DD"),
                        "days"
                      )
                    : 7
                },
                buttonText: "Overall list"
              }
            }}
            headerToolbar={fullCalendarHeader}
            allDaySlot={false}
            slotMinTime='07:00:00'
            slotMaxTime='17:00:00'
            slotDuration='00:15:00'
            validRange={{
              start: term.start,
              end: term.end
            }}
            editable
            selectable
            selectMirror
            firstDay={1}
            weekends={false}
            dayMaxEvents={5}
            dayHeaderContent={(args) => (
              <>
                <p>{moment(args.date).format("dddd")}</p>
                <p>{moment(args.date).format("DD MMMM")}</p>
              </>
            )}
            displayEventTime
            displayEventEnd
            select={handleEventClick}
            eventClick={handleEventClick}
            eventContent={renderEventContent}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              meridiem: false
            }}
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
              meridiem: false
            }}
            eventsSet={() => {
              if (calendarRef.current !== null) {
                const weekIndex = term.weeks.findIndex(
                  (item: WEEK) => item.weekNb === week.weekNb
                );

                if (weekIndex !== -1) {
                  // first week
                  if (weekIndex === 0) {
                    setFullCalendarHeader({
                      ...fullCalendarHeader,
                      left: "next"
                    });
                  }

                  // in between
                  if (weekIndex > 1 && weekIndex < term.weeks.length) {
                    setFullCalendarHeader({
                      ...fullCalendarHeader,
                      left: "prev next"
                    });
                  }

                  // last week
                  if (weekIndex === term.weeks.length) {
                    setFullCalendarHeader({
                      ...fullCalendarHeader,
                      left: "prev"
                    });
                  }

                  const currentStart =
                    calendarRef.current.getApi().view.currentStart;

                  const rangeStart = moment(currentStart, "YYYY-MM-DD");

                  // if range start after week end
                  if (
                    moment(rangeStart).isAfter(week.end) &&
                    weekIndex < term.weeks.length
                  ) {
                    setWeek(term.weeks[weekIndex + 1]);
                  }

                  // if range start before week end
                  if (
                    moment(rangeStart).isBefore(week.start) &&
                    weekIndex > 0
                  ) {
                    setWeek(term.weeks[weekIndex - 1]);
                  }
                } else {
                  setFullCalendarHeader({
                    ...fullCalendarHeader,
                    left: "prev next"
                  });
                }
              }
            }}
          />
        </>
      )}

      {action && (
        <AgendaModal
          selectedItem={selectedEvent}
          setSelectedItem={setSelectedEvent}
          action={action}
          setAction={setAction}
          loading={loading}
          setLoading={setLoading}
          refetch={getEvents}
          terms={terms}
          levels={levels}
        />
      )}
    </>
  );
}

export default Calendar;
